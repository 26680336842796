$pc-media:960px;
$pcs-media:959px;
$pad-media:760px;
$pads-media:759px;
$phone-media:480px;
$phones-media:479px;

#root{
    background-color: #ebebeb;
}

//PC
@mixin pc-width() {
@media all and (min-width: $pc-media) {
                @content;
        }
    }
//Pad
@mixin pad-width() {
@media all and (min-width:$pad-media) and (max-width: $pcs-media) {
                @content;
        }
}
//mini-pad
@mixin small-pad-width() {
        @media all and (min-width: $phone-media) and (max-width:$pads-media) {
                @content;
        }
}
//phone
@mixin phone-width() {
        @media all and (max-width: $phones-media) {
                @content;
        }
}


@mixin responsive-margin($pc,$pad,$small-pad,$phone) {

    @include pc-width {
        margin:$pc;
        }

    @include pad-width {
        margin:$pad;
        }

    @include small-pad-width {
        margin:$small-pad;
        }

    @include small-pad-width {
        margin:$phone;
        }
}
@mixin responsive-padding($pc,$pad,$small-pad,$phone) {

    @include pc-width {
        padding:$pc ($pc/2);
        }

    @include pad-width {
        padding:$pad ($pad/2);
        }

    @include small-pad-width {
        padding:$small-pad ($small-pad/2);
        }

    @include small-pad-width {
        padding:$phone ($phone/2);
        }
}
@mixin responsive-wrap($pc,$pad,$small-pad,$phone) {

    @include pc-width {
        flex-wrap:$pc;
        }

    @include pad-width {
        flex-wrap:$pad;
        }

    @include small-pad-width {
        flex-wrap:$small-pad;
        }

    @include small-pad-width {
        flex-wrap:$phone;
        }
}

.sc_container{
    background-color: #ebebeb;
    height:100vh;
    width: 100vw;
    font-size: 14px;
    font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
    @include responsive-padding(40px,30px,10px,8px);
    line-height: 1em;
    max-width: 1300px !important;



    h5{
        font: normal normal 600 18px/24px 'Open Sans';
        font-size: 18px;
    }
    h6{
        font: normal normal 600 14px/19px 'Open Sans';
        font-size: 14px;
    }

    .sc_header{
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        .sc_header_backbtn{
            border-radius: 20px;
            font-weight: 500;
        }
        .sc_header_title{
            display: flex;
            justify-content: center;
            h2{
                font: normal normal normal 30px/40px Open Sans;
                font-weight: 400;
                letter-spacing: 3px;
            }
        }
    }
    .sc_chat{
        
        height: 90%;
        .sc_chat_rooms{
            background-color: #f7f7f7;
            border-right: 8px solid #ebebeb;
            .sc_search{
                padding:12px 20px;
                .sc_search_box{
                    
                    .sc_search_btn{
                        border-radius: 30px 0px 0px 30px;
                        border:0px;
                        background-color: #fff;
                        height:2em;
                        font-size: 16px;
                        color:#ebebeb;

                        :hover{
                            color:#aaa ;
                        }
                    }
                    .sc_search_input{
                        border-radius: 0px 30px 30px 0px;
                        border:0px;
                        background-color: #fff;
                        height:2em;
                        font-size:16px;

                        &:focus{
                            color: #495057;
                            background-color: #fff;
                            border:0px solid;
                            outline: 0;
                            box-shadow: none;
                        }
                    }
                }
            }
            .sc_contacts{
                padding:0px 0px;
                font-size: 12px;
                .sc_contact{
                    background-color: #fff;
                    cursor:pointer;
                    margin-bottom: 3px;
                    
                    .sc_contact_item{
                        padding:10px;
                        img{
                            width:50px;
                            height:50px;
                            border-radius: 100px;
                            border:1px #eee solid;

                        }
                        .media-body{
                            padding-left:15px;
                            color:#707070;
                            font-weight: normal;
                            h6{
                                color:#707070;
                                font-weight: 600;
                                
                            }
                        }
                    }
                    .sc_contact_time{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        text-align: right;
                        span{
                            font: italic normal normal 10px/14px 'Open Sans';
                            letter-spacing: 0px;
                            color:#707070;
                        }
                        span.sc_contact_new{
                            background-color: #F59898;
                            border-radius: 30px;
                            font-size: 1em;
                            padding:2px 10px;
                            color:#fff;
                            align-self: flex-end;
                        }
                    }
                    &.active, &:hover{
                        background-color: #E6F8EC;
                    }
                }
                
            }
        }

        .sc_chat_content{
            background-color: #fff;
            display: flex;
            flex-direction: column;

            .sc_content_header{
                background-color: #035830;
                color:#fff;
                padding:7px 0px;
                flex:1;
                z-index: 100;
                .col{
                    display: flex;
                    .sc_header_back{
                        color:#fff;
                    }
                }
                .sc_header_item{
                    padding:5px;
                    align-items:stretch;
                    border: 1px;
                    border-color: #707070;
                    img{
                        width:60px;
                        height:60px;
                        border-radius: 15px;
                        border:0px;
                        background-color: #fff;
                    }
                    .media-body{
                        padding-left:15px;
                        font-weight: normal;
                        display: flex;
                        flex-direction:column;
                        justify-content: center;
                        h5{
                            font-weight: 500;
                            margin-bottom: 0px;
                        }
                        span{
                            font: italic normal normal 16px/21px 'Open Sans';
                        }
                    }
                }
                .sc_header_link{
                    display: flex;
                    align-items: center;
                    .sc_header_showrrombtn{
                        border:2px solid #fff;
                        border-radius: 20px;
                        color:#fff;
                        padding:2px 10px;
                        &:hover{
                            color:#eee;
                            border-color: #eee;
                        }
                    }
                }
            }
            .sc_content_msg{
                height:100%;
                align-content: flex-end;
                flex:30;
                z-index: 50;
                &> .col{
                    overflow-y: auto;
                    overflow-x: hidden;
                    display: flex;
                    flex-direction: column-reverse;
                    max-height:80vh;
                }

                .sc_content_in{
                    justify-content: flex-start;
                    .sc_content_bubble{
                        border-radius: 0px 40px 0px 0px;
                        background: #E6F8EC 0% 0% no-repeat padding-box;
                    }
                }
                .sc_content_out{
                    justify-content: flex-end;
                    .sc_content_bubble{
                        border-radius: 40px 0px 0px 0px;
                        background: #FCECE6 0% 0% no-repeat padding-box;
                    }
                }
                .sc_content_bubble{
                    flex:1 1 auto;
                    padding:20px 18px 10px 18px;
                    box-shadow: 0px 3px 6px #00000029;
                    margin:15px 0px;
                    line-height: 1.3em;
                    .sc_content_title{
                        font-weight: 500;
                    }
                    .sc_content_time{
                        text-align: right;
                        color: #808080;
                        font-style: italic;
                        font-size: 12px;
                        margin-top:10px;
                    }
                    .sc_content_showroom{
                        background-color: #fff;
                        border-radius: 15px ;
                        padding:15px 15px 15px 0px;
                        margin:10px 0px;
                        .sc_showroom_detail{
                            img{
                                max-height:100px;
                                max-width:100px;
                                object-fit: contain;
                                margin-right:10px;
                            }
                            .media-body{
                                font-weight: normal;
                                display: flex;
                                flex-direction:column;
                                justify-content:space-between;

                                .sc_showroom_type{
                                    font-style:italic;
                                    font-size: 12px;
                                }
                                span{
                                    font-size: 12px;
                                }
                                
                            }
                        }
                        .sc_showroom_link{
                            display: flex;
                            align-items:center;
                            .sc_showroom_btn{
                                background-color: #035830 ;
                                border-radius: 30px;
                                color:#fff;

                                &:hover{
                                    color: #fff;
                                    border:#707070;
                                }
                            }
                        }
                    }
                    .sc_content_product{
                        margin:10px 0px;
                        padding:15px 15px 15px 0px;
                        @include responsive-wrap(nowrap,wrap,wrap,wrap);
                        .sc_product_detail{
                            background-color: #fff;
                            border-radius: 15px ;
                            padding:5px;
                            margin:3px;
                            display: flex;
                            flex-direction: column;
                            div{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
                            }
                            img{
                                max-height:90px;
                                max-width:90px;
                                object-fit: contain;
                                margin-right:10px;
                            }
                            .sc_product_body{
                                font-weight: normal;
                                display: flex;
                                flex-direction:column;
                                justify-content:space-between;
                                h6{
                                    font-size: 12px;
                                }
                                .sc_product_type{
                                    font-style:italic;
                                    font-size: 12px;
                                }
                                span{
                                    font-size: 12px;
                                }
                                
                            }
                        }
                        .sc_product_link{
                            display: flex;
                            align-items:center;
                            align-self:center ;
                            .sc_product_btn{
                                background-color: #035830 ;
                                border-radius: 30px;
                                color:#fff;
                                padding: 2px 10px;

                                &:hover{
                                    color: #fff;
                                    border:#707070;
                                }
                            }
                        }
                    }
                }

            }
        }
        .sc_content_send{
            flex:1;
            margin-top: 5px;
            margin-bottom: 5px;
            z-index: 100;
            .sc_send_box{
                .sc_send_input{
                    background-color: #EFF1F2;
                    border:none;
                    border-radius: 5px;
                    padding:15px;
                    height: 50px;
                }
                .sc_send_btn{
                    background-color: #035830;
                    color: #fff;
                    border-radius: 5px;
                    margin-left: 0px;
                }
            }
            
        }
        
    }
}